import uk from 'vuetify/lib/locale/uk'

export default {
  back: 'назад',
  client: 'контрагент',
  choiceOneVariant: 'виберіть один варіант',
  comment: 'Коментар',
  copySendManager: 'копію буде надіслано менеджеру електронною поштою',
  equipment: 'обладнання',
  enterClientCode: 'введіть код клієнта',
  enterYourName: 'введіть ваше ім`я',
  enterYourPhone: 'введіть ваш телефон',
  enterYourVariant: 'введіть свій варіант',
  errorLoadingFromServer: 'помилка під час завантаження із сервера ',
  fault: 'Несправність',
  faultCodeIfAny: 'код несправності, якщо такий є',
  faultGroup: 'Група несправностей',
  group: 'Група',
  newOrder: 'Нове замовлення',
  location: 'локація',
  logInPlease: 'авторизуйтесь, будь ласка',
  next: 'далі',
  noAccess: 'Немає доступу! Будь ласка, перевірте правильність введених даних',
  other: 'інший',
  orderCreated: 'Заявку прийнято',
  orderNumber: 'Номер заказ-наряду',
  reason: 'Причина',
  retry: 'повторити',
  success: 'прийнято',
  serviceStopped: 'Обслуговування призупинено, детально уточнюйте у вашого менеджера.',
  wantAddSomething: 'хочете щось додати?',
  validation: {
    phone: 'має бути дійсний номер телефону: +XXXXXXXXXXX',
    required: 'не може бути порожнім',
  },
  $vuetify: uk
}
