import ru from 'vuetify/lib/locale/ru'

export default {
  back: 'назад',
  client: 'контрагент',
  choiceOneVariant: 'выберите один вариант',
  comment: 'Комментарий',
  copySendManager: 'копия будет отправлена менеджеру по электронной почте',
  enterClientCode: 'введите код клиента',
  enterYourName: 'введите ваше имя',
  enterYourPhone: 'введите ваш телефон',
  enterYourVariant: 'введите свой вариант',
  equipment: 'оборудование',
  errorLoadingFromServer: 'ошибка при загрузке с сервера',
  fault: 'Неисправность',
  faultCodeIfAny: 'код неисправности, если есть',
  faultGroup: 'Группа неисправностей',
  group: 'Группа',
  newOrder: 'Новый заказ',
  location: 'локация',
  logInPlease: 'авторизуйтесь, пожалуйста',
  next: 'далее',
  noAccess: 'Нет доступа! Пожалуйста, проверьте правильность введенных данных',
  other: 'другой',
  orderCreated: 'Заявка принята',
  orderNumber: 'Номер заказ-наряда',
  reason: 'Причина',
  retry: 'повторить',
  success: 'принято',
  serviceStopped: 'Обслуживание приостановлено, подробности уточняйте у вашего менеджера.',
  wantAddSomething: 'хотите что то добавить?',
  validation: {
    phone: 'должен быть действительным телефоном: +XXXXXXXXXXXX',
    required: 'не может быть пустым',
  },
  $vuetify: ru
}
