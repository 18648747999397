import en from 'vuetify/lib/locale/en'
export default {
  back: 'უკან',
  client: 'მომხმარებელი',
  choiceOneVariant: 'აირჩიეთ ერთი ვარიანტი',
  comment: 'კომენტარი',
  copySendManager: 'ასლი იქნება გაგზავნილი მენეჯერის ელ-ფოსტაზე',
  enterClientCode: 'შეიყვანეთ კლიენტის კოდი',
  enterYourName: 'შეიყვანეთ თქვენი სახელი',
  enterYourPhone: 'შეიყვანეთ თქვენი ტელეფონი',
  enterYourVariant: 'შეიყვანეთ თქვენი ვერსია',
  equipment: 'ტექნიკა',
  errorLoadingFromServer: 'შეცდომა სერვერიდან ჩატვირთვისას',
  fault: 'ხარვეზი',
  faultCodeIfAny: 'პრობლემის კოდი',
  faultGroup: 'ხარვეზის ტიპი',
  group: 'ჯგუფი',
  newOrder: 'ახალი შეკვეთა',
  location: 'ლოკაცია',
  logInPlease: 'გთხოვთ, გაიაროთ ავტორიზაცია ',
  next: 'შემდეგი',
  noAccess: 'წვდომა არ არის! გთხოვთ, შეამოწმეთ შეყვანილი ინფორმაცია',
  other: 'სხვა',
  orderCreated: 'შეკვეთა შექმნილია',
  orderNumber: 'შეკვეთის ნომერი',
  reason: 'მიზეზი',
  retry: 'სცადეთ ხელახლა',
  success: 'მიღებულია',
  serviceStopped:
    'მომსახურეობა შეჩერებულია, ინფორმაციისათვის დაუკავშირდით თქვენს მენეჯერს.',
  wantAddSomething: 'გსურთ რამის დამატება?',
  validation: {
    phone: 'აუცილებელია ნომრის შეყვანა: +XXXXXXXXXXXX',
    required: 'არ შეიძლება იყოს ცარიელი '
  },
  $vuetify: en
}
